import { combineReducers } from 'redux';
import { getToken } from './GetToken';
import { MyProfile } from './MyProfile';

const appReducer = combineReducers({
    token: getToken,
    myprofile: MyProfile,
})

const rootReducer = (state, action) => {
    if (action.type === 'logout_employee') {
        return undefined
    }

    return appReducer(state, action)
}

export default rootReducer;