export const GET_DETAILS = "get_user_details";
export const GET_USER_DETAILS_DOB = "get_user_data_dob";
const getDetails = (todo) => ({
  type: GET_DETAILS,
  payload: {
    ...todo,
  },
});

const getUserDetailsData = (todo) => ({
  type: GET_USER_DETAILS_DOB,
  payload: {
    ...todo,
  },
});

export const getPatientDetails = (data) => async (dispatch) => {
  //   const payload = {};
  dispatch(getDetails(data));
};

export const getUserDetails = (data) => async (dispatch) => {
  //   const payload = {};
  dispatch(getUserDetailsData(data));
};
