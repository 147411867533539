import { GET_DETAILS, GET_USER_DETAILS_DOB } from "../actions/PatientDetails";
const token = {
  data: [],
  userData: [],
};

export const MyProfile = (state = token, action) => {
  switch (action.type) {
    case GET_DETAILS:
      token.data = [];
      return Object.assign({}, state, { data: action.payload.data });

    case GET_USER_DETAILS_DOB:
      token.data = [];
      return Object.assign({}, state, { userData: action.payload.data });
    default:
      return state;
  }
};
