export const socketActions = {
  auth: "auth",
  referral: "referral",
};
export const socketSubActions = {
  getDeeplinkPayload: "getDeeplinkPayload",
};

export default class Socket {
  constructor(url = process.env.REACT_APP_SOCKET_URL) {
    // Create WebSocket connection.
    this.url = url;
    // this.socket = null;
    this.socket = new WebSocket(this.url);
  }

  send(string, callback = () => {}) {
    let $this = this;
    if (
      this.socket === null ||
      this.socket?.readyState === this.socket?.CLOSING ||
      this.socket?.readyState === this.socket?.CLOSED
    ) {
      this.socket = new WebSocket(this.url);
    }
    if (this.socket.readyState === this.socket.CONNECTING) {
      this.socket.addEventListener("open", function (event) {
        $this.socket.send(string);
      });
    } else {
      $this.socket.send(string);
    }

    // Listen for messages
    this.socket.onmessage = (data) => {
      let resp = data.data;
      if (typeof data.data === "string") {
        resp = JSON.parse(data.data);
      }
      callback(resp);
    };
  }
}
