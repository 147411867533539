import { GET_TOKEN } from '../actions/Token';
const token = {
    data: [],
}

export const getToken = (state = token, action) => {
    switch (action.type) {
        case GET_TOKEN:
            state = token
            return Object.assign({}, state, { data: action.payload.data });

        default:
            return state;
    }
} 