export const StatusCode = {
  New: 201,
  Success: 200,
  Deleted: 204,
  Session_Expired: 440,
  Bad_Request: 400,
  Validation_Error: 422,
  Not_Found: 404,
  API_ERROR: 401,
  Internal_Server_Error: 500,
};

export const monthName = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const ListingRecord = {
  record: 15,
};

export const Modules = {
  profile: "/my-account",
  HOME: "/",
  MEDIA_VIEW: "/mediaview",
  VIDEO_CALL: "/video-call",
  VIDEO_CALL_INI: "/telehealth",
  CONTENT_VIEW: "/content",
  DEEP_LINK: "/deeplink/:slug"
};

export const MobilePrefix = {
  prefix: "+1",
};
