import React, { Component, lazy, Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Modules } from "./config/Constant";
import Socket from "./Websocket";

const Layout = lazy(() => import("./component/Layout"));
const DeepLink = lazy(() => import("./screen/DeepLink"));

window.socket = new Socket();
class AppRouting extends Component {
  AuthCheck = () => {
    return localStorage.getItem("token") ? true : false;
  };
  render() {
    const PrivateRoute = ({ component: Component, ...rest }) => (
      <Route
        {...rest}
        render={(props) =>
          this.AuthCheck() ? (
            <Component {...props} />
          ) : (
            document.addEventListener("readystatechange", (event) => {
              if (event.target.readyState === "complete") {
                window.location.href = "/";
              }
            })
          )
        }
      />
    );
    return (
      <React.Fragment>
        <BrowserRouter>
          <div className="AppRouting">
            <Switch>
              <Suspense fallback={<div></div>}>
                <Route exact path={Modules.DEEP_LINK} component={DeepLink} />

                <Route exact path={Modules.HOME} component={Layout} />

                <Route exact path={Modules.CONTENT_VIEW + "/:slug"} component={Layout} />
                <Route exact path={Modules.VIDEO_CALL} component={Layout} />
                <Route exact path={Modules.VIDEO_CALL_INI} component={Layout} />
                <Route exact path={Modules.MEDIA_VIEW} component={Layout} />
                {/* <PrivateRoute exact path={Modules.MEDIA_VIEW} component={Layout} /> */}
              </Suspense>
            </Switch>
          </div>
        </BrowserRouter>
      </React.Fragment>
    );
  }
}

export default AppRouting;
