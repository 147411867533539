import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store";
import "react-toastify/dist/ReactToastify.css";
import "./scss/style.scss";
import { ToastContainer } from "react-toastify";

import AppRoute from "./Approuting";

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <div className="App">
          <ToastContainer position="top-center" />
          <AppRoute />
        </div>
      </PersistGate>
    </Provider>
  );
}

export default App;
